const $ = window.jQuery

const responsiveOverride = function(event, slick){
  if ($(this).is('s24-slick-initialized')) {
    return
  }

  let slidesToShow = [1,2,3,4]
  if ($(this).parents('.custom-post-type-page.alignwide').length) {
    slidesToShow = [1,3,4,4]
  }
  if ($(this).parents('.modal-body').length) {
    slidesToShow = [1,1,2,3]
  }
  
  let responsiveOption = [
    {
      breakpoint: 1289,
      settings: {
        slidesToShow: slidesToShow[2],
        slidesToScroll: slidesToShow[2]
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: slidesToShow[1],
        slidesToScroll: slidesToShow[1]
      }
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: slidesToShow[0],
        slidesToScroll: slidesToShow[0]
      }
    }
  ];
  slick.slickSetOption('slidesToShow', slidesToShow[3]);
  slick.slickSetOption('slidesToScroll', slidesToShow[3]);
  slick.slickSetOption('responsive', responsiveOption, true);
  slick.refresh()
  $(this).addClass('s24-slick-initialized');
}

// Slick progress bar
// https://kenwheeler.github.io/slick/
const progressBar = function(event, slick){
  if ($(this).is('s24-slick-progressbar-initialized')) {
    return
  }

  const waitOverlay = ($('.s24-hero-section--overlay').length) ? 3000 : 0;
  const $parent = $(this).parent()
  if(slick.options.autoplay && slick.options.autoplaySpeed) {
    // slick.$slider.css({opacity: 0}).animate({opacity: 1}, 1200)
    slick.slickPause()
    setTimeout (()=>{
      $parent.append('<div class="slider-propgress"><div class="ct-container"><div class="slider-propgress__bar"></div></div></div>')
      const $bar = $parent.find('.slider-propgress__bar')
      $bar.animate({width: '100%'}, slick.options.autoplaySpeed)

      slick.slickPlay()
      slick.$slider.on('afterChange', function(event, slick, currentSlide){
        $bar.css('width', 0)
        $bar.animate({width: '100%'}, slick.options.autoplaySpeed)
      });
    }, waitOverlay)
  }
  $(this).addClass('s24-slick-progressbar-initialized');
}

const appInit = function() {
  // slick init event handler must be set before slick initialization, that occurs before pageview init for carousel in server side markup  
  $('.wp-block-getwid-post-carousel__wrapper').on('init', responsiveOverride);
  $('.wp-block-getwid-content-slider__wrapper').on('init', progressBar);
}

const init = function() {
  // Slick carousel: breakpoinst override
  // https://github.com/kenwheeler/slick
  $('.wp-block-getwid-post-carousel__wrapper').on('init', responsiveOverride);
  $('.wp-block-getwid-content-slider__wrapper').on('init', progressBar);
}

const destroy = function() {
  $('.wp-block-getwid-post-carousel__wrapper').off('init', responsiveOverride);
  $('.wp-block-getwid-content-slider__wrapper').off('init', progressBar);
}

export default {init, destroy, appInit}