require('intersection-observer')

let tocEl;
let contentWrapperEl;
let headings;
let linkItems;
let previousSection;

const contentWrapperShowStiky = 'toc-sticky-show'
const stickyTop1 = 45
const stickyTop2 = stickyTop1 + 95
const tocItemsObserverOptions = {
 rootMargin: '-' + stickyTop2 + 'px 0px 0px 0px',
 threshold: 1.0
};
const tocItemsObserverCallback = (entries, observer) => {
  entries.forEach(entry => {
    const id = entry.target.getAttribute('id')
    let href = `#${id}`
    let link = linkItems.find(l => l.getAttribute('id') === ('li-' + id))

    if (link) {
      // entry.intersectionRatio > 0
      if (entry.isIntersecting && entry.intersectionRatio === 1) {
        link.classList.add('is-visible')
        previousSection = id
      } else {
        link.classList.remove('is-visible')
      }
      highlightFirstActive()
    }
  })
};
const highlightFirstActive = () => {
  let firstVisibleLink = document.querySelector('[data-toc-item].is-visible')

  if (firstVisibleLink || previousSection) {
    linkItems.forEach(link => {
      link.classList.remove('is-active')
    })
  }

  if (firstVisibleLink) {
    firstVisibleLink.classList.add('is-active')
  }

  if (!firstVisibleLink && previousSection) {
    let link = linkItems.find(l => l.getAttribute('id') === ('li-' + previousSection))
    if (link) {
      link.classList.add('is-active')
    }
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver
// https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/observe
// https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/unobserve
// https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/disconnect
const tocItemsObserver = new IntersectionObserver(tocItemsObserverCallback, tocItemsObserverOptions);


let previousY = 0
let previousRatio = 0
const tocObserverOptions = {
 rootMargin: '-' + stickyTop1 + 'px 0px 0px 0px',
 threshold: [0.0]
};
const tocObserverCallback = (entries, observer) => {
  entries.forEach(entry => {
    /*
    const isAbove = entry.boundingClientRect.y < entry.rootBounds.y;
    if (entry.isIntersecting) {
        if (wasAbove) {
            // Comes from top
        }
    }
    wasAbove = isAbove;
    */

    const currentY = entry.boundingClientRect.y
    const currentRatio = entry.intersectionRatio
    const isIntersecting = entry.isIntersecting
    // Scrolling down/up
    if (currentY < previousY) {
      if (currentRatio > previousRatio && isIntersecting) {
        // console.log("Scrolling down enter")
        contentWrapperEl.classList.remove(contentWrapperShowStiky)
      } else {
        // console.log("Scrolling down leave")
        contentWrapperEl.classList.add(contentWrapperShowStiky)
      }
    } else if (currentY > previousY && isIntersecting) {
      if (currentRatio < previousRatio) {
        // console.log("Scrolling up leave")
        contentWrapperEl.classList.add(contentWrapperShowStiky)
      } else {
        // console.log("Scrolling up enter")
        contentWrapperEl.classList.remove(contentWrapperShowStiky)
      }
    }

    previousY = currentY
    previousRatio = currentRatio
  })
};
const tocObserver = new IntersectionObserver(tocObserverCallback, tocObserverOptions);

const init = function() {
  tocEl = document.querySelector('[data-toc]')
  contentWrapperEl = document.querySelector('[data-toc-sticky-wrapper]')
  if (tocEl && contentWrapperEl) {
    jQuery(contentWrapperEl).prepend('<div data-toc-sticky class="toc-sticky block-document-contents">' +  tocEl.innerHTML + '</div>')
    tocObserver.observe(tocEl)
  }

  linkItems = [...document.querySelectorAll('[data-toc-item]')]
  headings = document.querySelectorAll('[data-toc-heading]')
  headings.forEach(target => tocItemsObserver.observe(target))
}

const destroy = function() {
  if (tocEl && contentWrapperEl) {
    tocObserver.unobserve(tocEl)
  }

  headings.forEach(target => tocItemsObserver.unobserve(target))
}

export default {init, destroy}
