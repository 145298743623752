const $ = window.jQuery

// https://getbootstrap.com/docs/5.3/getting-started/javascript/
// require('bootstrap/js/dist/modal.js')
// import ScrollSpy from 'bootstrap/js/dist/scrollspy'
import Modal from 'bootstrap/js/dist/modal'

import toc from './tableofcontent'

import { debounce, throttle  } from 'lodash';

import slickEvents from './modules/slick_events';

const $window = $(window)
const windowResizeHandler = throttle(  function(e) {
  $window.triggerHandler('resize')
}, 400, {
  'leading': true,
  'trailing': true
} )

let $activeModal = null
const fetchModalBody = function(event) {
  const button = event.relatedTarget
  // Extract info from data-bs-* attributes
  $activeModal = $(button.getAttribute('data-bs-target'))
  const $activeModalBody = $activeModal.find('.modal-body')
  
  const iframeSrc = button.getAttribute('data-bs-modaliframe')
  const recipient = button.getAttribute('data-bs-modalfetch') || button.getAttribute('href')
  if (iframeSrc) {
    const iframeHtml = `<div class="ratio ratio-16x9"><iframe src="${iframeSrc} "allowfullscreen  webkitallowfullscreen  mozallowfullscreen ></iframe></div>`
    $activeModalBody.html(iframeHtml);
  } else if (recipient) {
    // Update the modal's content.
    $activeModalBody.html('<div class="p-5 text-center">Loading...</div>');
    $.ajax( {
      method: "GET",
      dataType: 'html',
      url: recipient
    }).done(function( data ) {
      const $data = $(data)

      /*
      * get block inline styles
      */
      const $blockStyles = $data.filter('style[id^="wp-block-"]')
      let blockStylesHtml = ''
      for(var i = 0; i < $blockStyles.length; i++) {
        blockStylesHtml = blockStylesHtml + $blockStyles[i].outerHTML
      }

      /*
      * get modal markup
      */
      const $modalBody = $data.find('#main')
      $activeModalBody.html( blockStylesHtml +  $modalBody.html() );

      /*
      * Getwid counter starts on window scroll (waypoints)
      * not working on modal scroll; as a fix, in modal, set counter to its final value or trigger the counter immediatelly
      */

      slickEvents.init() // before Getwid Blocks initialization!
      // https://wordpress.org/support/topic/initialise-getwid-blocks-after-appending-post-to-document/
      // Getwid Blocks initialization!
      $( document.body ).trigger( 'post-load' );
    })
  }

  /*
  * Dirty way to trigger waypoint refresh (used bu Getwid blocks)
  * http://imakewebthings.com/waypoints/api/context-option/
  * 
  * A cleaner approach coud be reinit Waypoints
  */
  $activeModal.on('scroll', windowResizeHandler)
  $activeModal = $activeModal
}

// reset model content, to remove iframe embed
const resetModalBody = function(event) {
  if ($activeModal.is(':hidden')) {
    const $activeModalBody = $activeModal.find('.modal-body')
    $activeModalBody.html('');
    $activeModal.off('scroll', windowResizeHandler)
    $activeModal = null
  }
}


const onDocumentReady = function() {
  //https://getbootstrap.com/docs/5.3/components/modal/ 
  //https://getbootstrap.com/docs/5.3/components/modal/#varying-modal-content 
  const modelFetch = document.querySelectorAll('.modal-fetch')
  if (modelFetch) {
    modelFetch.forEach(element => {
      element.addEventListener('show.bs.modal', fetchModalBody);
      element.addEventListener('hidden.bs.modal', resetModalBody);
    });
  }

  const filterOnClick = function(){
    const $this = $(this)
    const $grid = $this.closest('.wp-block-getwid-custom-post-type')
    const [allSelector, showSelector] = $this.attr('data-filter').split('|')
    $grid.find('[data-search]').val('') // reset search input
    if (!$this.hasClass('active')){
      $grid.find(allSelector).parent().hide()
      $grid.find(showSelector).parent().show().css({opacity: 0}).animate({opacity: 1}, 400)
      $grid.find('.active').removeClass('active')
      $this.addClass('active')
    } else {
      $grid.find(allSelector).parent().show().css({opacity: 0}).animate({opacity: 1}, 400)
      $grid.find('.active').removeClass('active')
      $this.removeClass('active')
    }
  }
  $('[data-filter]').on('click', debounce(filterOnClick, 300, {
    'leading': true,
    'trailing': false
  } ) )

  const searchOnKeyup = function(){
    const $this = $(this)
    const $grid = $this.closest('.wp-block-getwid-custom-post-type')
    const allSelector = $this.attr('data-search')
    if ($this.val().length > 2) {
      const searchValue = $this.val().toLowerCase()
      $grid.find(allSelector).parent().hide()
      $grid.find('.active').removeClass('active') // reset filter buttons
      $grid.find(allSelector).filter(function(){
        if ($(this).attr('title').toLowerCase().indexOf(searchValue) !== -1) {
          return true
        } else {
          return false
        }
      }).parent().show().css({opacity: 0}).animate({opacity: 1}, 400)
    } else {
      $grid.find(allSelector).parent().show().css({opacity: 0}).animate({opacity: 1}, 400)
      $grid.find('.active').removeClass('active')
    }
  }
  // https://lodash.com/docs/4.17.15#debounce
  $('[data-search]').on('keyup', debounce(searchOnKeyup, 300, {
    'leading': true,
    'trailing': true
  } ) )

  function searchResetOnKeyup() {
    const $this = $(this)
    const $searchInput = $this.siblings('[data-search]')
    const $grid = $this.closest('.wp-block-getwid-custom-post-type')
    const allSelector = $searchInput.attr('data-search')
    $grid.find(allSelector).parent().show().css({opacity: 0}).animate({opacity: 1}, 400)
    $grid.find('.active').removeClass('active')
    $searchInput.val('')
  }
  $('[data-search-reset]').on('click', searchResetOnKeyup)

  // slickEvents.appInit()
  slickEvents.init()

  // https://getbootstrap.com/docs/5.2/components/scrollspy/
  const TABLE_OF_CONTENTS_SELECTOR = '.wp-block-getwid-table-of-contents'
  if ($(TABLE_OF_CONTENTS_SELECTOR).length) {
    /*
    const scrollSpy = new ScrollSpy($('html'), {
      target: TABLE_OF_CONTENTS_SELECTOR
    })
    */
    
    $(TABLE_OF_CONTENTS_SELECTOR).attr('data-toc', '')
    $(TABLE_OF_CONTENTS_SELECTOR).find('ul, ol').wrap('<div class="document-contents-wrapper">')
    
    $('#main').attr('data-toc-sticky-wrapper', '')
    $(TABLE_OF_CONTENTS_SELECTOR).find('li').each(function() {
      $(this).attr('data-toc-item', '')
      let $a = $(this).find('a')
      // $(this).attr('data-remove-class', 'toc-sticky-expanded') (( not working with scroll-behaviour smooth))
      let href = $a.attr('href')
      $(this).attr('id', 'li-' + href.substring(1))
      $(href).attr('data-toc-heading', '')
    })
    $('body').addClass('has-toc')
    toc.init()
  }

  $(document)
    .on('click', '[data-toggle-class]', function(e){
      // e.preventDefault()
      $('html').toggleClass($(this).attr('data-toggle-class'))
    })

  const resetClasses = function() {
    $('html').removeClass('toc-sticky-expanded')
  }
  window.addEventListener('scroll', throttle(resetClasses, 400, {
    'leading': true,
    'trailing': false
  } ) );

  const onWindowResize = function() {
    $('[data-hscroll]').each(function(index, element) {
      // compare with element.offsetWidth if elements are not abs postitionet once hovered
      if (window.innerWidth < element.scrollWidth) {
         $(this).addClass('data-hscroll-active')
      } else {
        $(this).removeClass('data-hscroll-active')
      }
    })
  }
  onWindowResize()
  window.addEventListener('resize', throttle(onWindowResize, 400, {
    'leading': true,
    'trailing': true
  } ) );
  $('[data-hscroll]').each(function(index, element) {
    // https://stackoverflow.com/questions/10744645/detect-touchpad-vs-mouse-in-javascript/62415754#62415754
    element.addEventListener('wheel', (e) => {
      // const isTouchPad = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0 // not working on FF
      const isTouchPad = (Math.abs(e.wheelDeltaY) < 30)
      if (!isTouchPad) {
        e.preventDefault();
        const delta = e.deltaY < 0 ? -30 : 30
        element.scrollLeft -= delta;
      }
    });
  })
}

$(document).on('ready', onDocumentReady);